import React from "react"
import styled from "styled-components"
import { graphql, Link } from "gatsby"
import { getCurrentLangKey, getLangs, getUrlForLang } from "ptz-i18n"
import { IntlProvider } from "react-intl"
import "intl"
import Layout from "../../../Layout"
import Navbar from "../../../Navbar"
import Footer from "../../../Footer"
import { Container } from "react-bootstrap"
import Pagination from "../../../Pagination"
import useWindowSize from "../../../WindowDimensions"
import {
  Heading24,
  Heading32,
  Paragraph18,
} from "../../../../styles/PageStyles"

const JFEEINewsId = ({ data, pageContext, location, i18nMessages }) => {
  const url = location.pathname
  const { langs, defaultLangKey } = data.site.siteMetadata.languages
  const langKey = getCurrentLangKey(langs, defaultLangKey, url)
  const homeLink = `/${langKey}/`
  const langsMenu = getLangs(langs, langKey, getUrlForLang(homeLink, url))

  const { height } = useWindowSize()
  const viewport = height - 235
  const news = data.allMarkdownRemark.nodes
  const { numPages } = pageContext

  return (
    <IntlProvider locale={langKey} messages={i18nMessages}>
      <Layout>
        <Navbar langKey={langKey} langs={langsMenu} url={url} />
        <StyledContainer fluid viewport={`${viewport}px`}>
          <div className="heading">
            <Heading32>
              {langKey === "id"
                ? data.markdownRemark.frontmatter.jfeei_news.title
                : "JFE Engineering Indonesia News"}
            </Heading32>
          </div>
          {news.length > 1 ? (
            <>
              {news.map((article, index) => (
                <div className="news-container" key={index}>
                  <div className="news">
                    <div>
                      <Paragraph18 className="date">
                        {article.frontmatter.date}
                      </Paragraph18>
                    </div>
                    <div>
                      <Link to={article.fields.slug}>
                        <Paragraph18 className="title">
                          {article.frontmatter.title}
                        </Paragraph18>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
              <Pagination
                numPages={numPages}
                basePath="news"
                slug="jfe-engineering-indonesia"
              />
            </>
          ) : (
            <div>
              <Heading24>No news found.</Heading24>
            </div>
          )}
        </StyledContainer>
        <Footer langKey={langKey} />
      </Layout>
    </IntlProvider>
  )
}

export default JFEEINewsId

const StyledContainer = styled(Container)`
  min-height: ${(props) => props.viewport || "100vh"};
  padding: 190px 10% 100px 10%;

  .news-container ~ .news-container {
    display: block;
  }

  .news-container {
    display: none;
    border-bottom: 2px solid #e9eff1;
  }

  .heading {
    margin-bottom: 40px;
  }

  .news {
    display: flex;
    align-items: center;
    padding: 20px 0;

    .date,
    .title {
      font-weight: bold;
      letter-spacing: 0.06em;
    }

    .date {
      color: #5b5d5e;
      white-space: nowrap;
      width: 300px;
    }

    .title {
      text-transform: capitalize;
      color: #0073af;
    }

    @media only screen and (min-width: 1px) and (max-width: 1440px) {
      .date {
        width: 200px;
      }
    }

    @media only screen and (max-width: 426px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  @media only screen and (max-width: 769px) {
    padding: 140px 5% 50px 5%;
  }
`

export const query = graphql`
  query newsIdPageQueryId($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(id)/(jfeei-news)/" } }
      sort: { fields: frontmatter___date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        id
        html
        fields {
          slug
        }
        frontmatter {
          title
          description
          date(formatString: "MMMM DD, YYYY")
        }
      }
    }
    markdownRemark(frontmatter: { templateKey: { eq: "home-id" } }) {
      frontmatter {
        jfeei_news {
          title
        }
      }
    }
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`
